import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { node } from 'prop-types';

import Favicon from '@assets/images/caitlyn-chris-nye-new-v2.jpg';
import '@assets/css/icomoon.css';
import '@assets/css/bootstrap.css';
import '@assets/css/style.css';

function MainLayout({ children }) {
  return (
    <Fragment>
      <Helmet>
        <title>caitlyn & chris wedding</title>

        {/* Favicon */}
        <link rel="icon" type="image/png" href={Favicon} />

        {/* font and SEO tags */}
        <meta property="og:title" content="You're Invited to Caitlyn & Chris' Wedding" />
        <meta property="og:site_name" content="The Wedding of Caitlyn & Chris" />
        <meta property="og:description" content="You're Invited to Caitlyn & Chris' Wedding" />
        <meta property="og:image" content={Favicon} />

        <link
          href="https://fonts.googleapis.com/css?family=Work+Sans:400,300,600,400italic,700"
          rel="stylesheet"
          type="text/css"
        />
        <link href="https://fonts.googleapis.com/css2?family=Cookie&display=swap" rel="stylesheet"></link>
      </Helmet>
      <div id="page">{children}</div>
    </Fragment>
  );
}

MainLayout.propTypes = {
  children: node.isRequired,
};

export default MainLayout;
